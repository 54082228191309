<template>
	<div class="flexPage darkPage">
		<div class="currencyHead fixed themeHead">
			<navbar :params="pageInfo">
				<template #right>
					<img src="../../assets/img/record.png" alt="" style="width: 24px;height: 24px;">
				</template>
			</navbar>
		</div>
		<div class="flexContent mt25">
			<div class="recharge">
				<div class="rechargeTitle">{{ $t('other.translate161') }}</div>
				<el-input v-model="number" style="width: 280px;height: 36px;margin-top: 50px;"
					:placeholder="$t('other.translate162')" />
				<van-button class="btn" :lang="$i18n.locale" @click="sure">{{ $t('other.translate163') }}</van-button>
			</div>
			<!-- <div class="cell">
				<img src="../../assets/img/coin.png" alt="" class="img">
				<span class="coinName">{{ coinName.coin.name }}</span>
			</div>
			<div class="qrCode mt25">
				<qrcode class="qrcode" :value="walletAddress"></qrcode>
				<p class="address">{{ walletAddress }}</p>
				<p class="addressCopy" @click="$mts.copy(walletAddress)">{{ $t('form.assets.copyAddr') }}</p>
			</div> -->

			<!-- 
			<div class="acountAddr">
				<h3>{{ $t('form.assets.cbAddr') }}</h3>
				<div class="addr">{{ processAddress(walletAddress) }}</div>
				<button class="copy" @click="$mts.copy(walletAddress)">{{ $t('form.assets.copyAddr') }}</button>
			</div> -->

			<div class="promptInfo mt25">
				<span>{{ $t('other.translate73') }}:</span>
				<br>
				<p></p>
				<p>1、{{ $t('other.translate74') }}</p>
				<p>2、{{ $t('other.translate75') }}</p>
				<p>3、{{ $t('other.translate76') }}</p>
			</div>
			<div class="orderListBoxs" v-for="item in listNode">
				<div class="order">
					<span>{{ $t('other.translate45') }}: {{ item.id }}</span>
					<div class="statusNode" v-if="item.state == 'COMPLETE'">{{ $t('other.translate65') }}</div>
					<div class="statusNodeF" v-if="item.state == 'DEFEATED'">{{ $t('other.translate66') }}</div>
					<div class="statusNodeW" v-if="item.state == 'APPLYING'">{{ $t('other.translate67') }}</div>
				</div>
				<div style="height: 90px;display: flex;flex-wrap: wrap;align-content: space-between">
					<div class="orders">
						<span>{{ $t('other.translate177') }}: </span>
						<span>{{ item.remainingTime }}</span>
					</div>
					<div class="orders">
						<span>{{ $t('other.translate68') }}: </span>
						<span>{{ processAddress(item.address) }}</span>
						<img src="../../assets/img/copy.png" @click="$mts.copy(item.address)" alt=""
							style="width: 24px;height: 24px;margin-left: 5px;">
					</div>
					<div class="orders">
						<span>{{ $t('other.translate69') }}: </span>
						<span>{{ item.coin.name }}</span>
					</div>
					<div class="orders">
						<span>{{ $t('other.translate53') }}: </span>
						<span>{{ item.amount }}</span>
						<img src="../../assets/img/copy.png" @click="$mts.copy(item.amount.toString())" alt=""
							style="width: 24px;height: 24px;margin-left: 5px;">
					</div>
					<div class="orders">
						<span>{{ $t('other.translate70') }}: </span>
						<span>{{ item.creationTime }}</span>
					</div>
				</div>
			</div>
		</div>
		<van-dialog v-model="showModifyStop" :title="$t('other.translate168')" :show-cancel-button="false"
			:showConfirmButton="false">
			<div class="titleNode">{{ $t('other.translate164') }}</div>
			<div class="titleNodes">{{ $t('other.translate165') }}</div>
			<div class="number">
				{{ amount }}
				<img src="../../assets/img/copy.png" alt="" style="width: 24px;height: 24px;margin-left: 5px;"
					@click="$mts.copy(amount.toString())">
			</div>
			<div class="addressNodes">{{ $t('other.translate166') }}：{{ address }} <img src="../../assets/img/copy.png"
					alt="" style="width: 24px;height: 24px;margin-left: 5px;" @click="$mts.copy(address)"></div>
			<div class="btnBox">
				<div class="btnC" @click="sureNode">{{ $t('other.translate167') }}</div>
			</div>
		</van-dialog>
		<!-- <van-action-sheet v-model="chooseCoin.show" :actions="coins" @select="onSelect" /> -->
	</div>
</template>
<script>
import number from "@/views/modules/number.vue";
import navbar from "@/views/modules/navbar.vue"
import store from "./store/common"
export default {
	data() {
		return {
			pageInfo: {
				customTitle: this.$t('assets.index.n1'),
				url: '/depositHistory',
				value: '',
				icon: 'orders-o',
				navbarStyle: 'normal'
			},
			chooseCoin: {
				show: false,
				name: '',
			},
			coinList: [],
			walletAddress: '',
			rechargeCoinList: [],
			coinName: '',
			showModifyStop: false,
			number: '',
			amount: '',
			address: '',
			listNode: [],
			countdown: {
				minutes: '',
				seconds: ''
			},
			intervalId: null
		}
	},
	components: { number, navbar },
	watch: {},
	computed: {},
	created() {
		this.getData()
		this.initRechargeCoinList();
		this.coinName = store.state.currency
		this.getMoney(store.state.currency.coin.name)
		this.calculateCountdown(); // 组件创建时开始倒计时
	},
	beforeDestroy() {
		clearInterval(this.intervalId)
	},
	methods: {
		// 截取字符串
		processAddress(address) {
			if (address.length < 23) {
				return address;
			}
			var prefix = address.substring(0, 19);
			var suffix = address.substring(address.length - 4);
			return prefix + "..." + suffix;
		},
		// 获取充值币种
		initRechargeCoinList() {
			let that = this;
			this.$http.post(this.host + '/uc/coin/recharge/supported').then((response) => {
				var resp = response.body;
				if (resp.code == 0) {
					if (resp.data.length > 0) {
						that.rechargeCoinList = resp.data;

					}
				} else {
					this.$toast(resp.message);
				}
			});
		},
		//获取充值地址
		getMoney(coinName) {
			let params = {};
			params['coinName'] = coinName;
			this.$http.post(this.host + '/uc/recharge/address', params).then((response) => {
				let resp = response.body;
				if (resp.code == 0) {
					this.coinList = resp.data;
					for (var i = 0; i < this.coinList.length; i++) {
						this.walletAddress = this.coinList[i].address;
					}
				} else {
					this.$toast(resp.message); // 获取充币地址失败
				}
			});
		},
		async sure() {
			if (!this.number) {
				this.$toast(this.$t('other.translate171'))
			} else if (this.number < 0 || this.number == 0 || this.number % 1 != 0) {
				this.$toast(this.$t('other.translate174'))
			} else {
				this.$http.post(this.host + `/uc/legal-wallet-recharge?amount=${this.number}&unit=USDT`).then((response) => {
					if (response.body.code == 0) {
						this.showModifyStop = true
						this.amount = response.body.data.amount
						this.address = response.body.data.address
					} else {
						this.$toast(response.data.message);
					}
				})
			}

		},
		getData() {
			this.$http.get(this.host + `/uc/legal-wallet-recharge?pageNo=${1}&pageSize=100`).then(res => {
				this.listNode = res.body.data.list.filter(item => item.state == 'APPLYING').map(params => {
					return {
						...params,
						remainingTime: ''
					}
				})
			});
		},
		sureNode() {
			this.getData()
			this.showModifyStop = false
		},
		calculateCountdown() {
			this.intervalId = setInterval(() => {
				this.listNode = this.listNode.map(item => {
					const now = Math.floor(Date.now() / 1000);
					const fifteenMinutes = 20 * 60;
					const timestampPlus15Minutes = new Date(item.creationTime).getTime() / 1000 + fifteenMinutes;
					const distance = timestampPlus15Minutes - now;
					// 计算剩余时间
					const minutes = Math.floor(distance / 60);
					const seconds = distance % 60;
					item.remainingTime = `${minutes} ${this.$t('other.translate175')} ${seconds} ${this.$t('other.translate176')}`;
					if (distance <= 0) {
						item.remainingTime = `0 ${this.$t('other.translate175')} 0 ${this.$t('other.translate175')}`;
					}
					// 更新剩余时间到对象中
					return { ...item }
				});
			}, 1000)

		}

	}
}
</script>

<style scoped>
.btn:lang(zh-CN) {
	width: 133px;
	height: 44px;
	background-color: #000000;
	color: #fff;
	border-radius: 8px;
	margin-top: 30px;
}

.btn:lang(en-US) {
	width: 163px;
	height: 44px;
	background-color: #000000;
	color: #fff;
	border-radius: 8px;
	margin-top: 30px;
}

.statusNodeW {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #0052FF33;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #0052FF;

}

.statusNodeF {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #EB433533;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #EB4335;

}

.statusNode {
	width: auto;
	min-width: 57px;
	height: 23px;
	padding: 0 5px;
	background: #3AD29F33;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 23px;
	text-align: center;
	border-radius: 4px;
	color: #3AD29F;

}

.orders span:first-child {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	color: #00000080;
	margin-right: 8px
}

.orders span:last-child {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	color: #000;

}

.orders {
	width: 100%;
	display: flex;
	align-items: center
}

.order {
	width: 100%;
	font-family: Inter;
	font-size: 14px;
	font-weight: 600;
	text-align: left;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.orderListBoxs {
	width: 100%;
	padding: 0 16px;
	margin-top: 17px;
	border-bottom: 1px solid #0000001A;
	padding-bottom: 16px
}

.addressNodes {
	width: 289px;
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	margin: 0 auto;
	margin-top: 25px;

}

.titleNode {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 16.8px;
	text-align: center;
	margin-top: 25px
}

.titleNodes {
	font-family: Inter;
	font-size: 12px;
	font-weight: 500;
	line-height: 16.8px;
	text-align: center;
	color: red;
	margin-top: 15px
}

.number {
	font-family: Inter;
	font-size: 4.5802vw;
	font-weight: 600;
	margin-top: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.btnBox {
	width: 54.9618vw;
	margin: 0 auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 7.4504vw;
}

::v-deep .van-dialog__header {
	font-family: Inter;
	font-size: 4.5802vw;
	font-weight: 600;
}

.btnC {
	height: 9.1603vw;
	width: 105.4453vw;
	font-family: Inter;
	font-size: 3.5623vw;
	font-weight: 600;
	line-height: 9.1603vw;
	text-align: center;
	color: #fff;
	background-color: #000000;
	border-radius: 8px;
}

.btnS {
	height: 9.1603vw;
	width: 25.4453vw;
	font-family: Inter;
	font-size: 3.5623vw;
	font-weight: 600;
	line-height: 9.1603vw;
	text-align: center;
	background: #0052FF;
	color: #fff;
	border-radius: 1.0178vw
}

::v-deep .van-dialog {
	width: 84.9873vw;
	height: 91.7557vw;
	border-radius: 4.0712vw;
}

::v-deep .van-field__label {
	padding-top: 1.7812vw !important
}

::v-deep .van-dialog__footer {
	border: none !important;
	margin-top: 17.5573vw
}

.rechargeTitle {
	font-family: Inter;
	font-size: 18px;
	font-weight: 600;
	text-align: center;

}

.recharge {
	width: 332px;
	height: 260px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.addressCopy {
	width: 231px;
	word-break: break-all;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #0052FF
}

.address {
	width: 231px;
	word-break: break-all;
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	letter-spacing: 0.02em;
	text-align: center;
	color: #000
}

.mt25 {
	margin-top: 6.3613vw;
}

.img {
	width: 20px;
	height: 18px;
	margin-right: 5px
}

.coinName {
	font-family: Inter;
	font-size: 20px;
	font-weight: 600;
}

.cell {
	display: flex;
	align-items: center;
	justify-content: center;
}

.cell .cellLeft {
	color: #606266;
}

.qrcode {
	width: 185px !important;
	height: 185px !important;
}

.qrCode {

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.acountAddr {
	margin: 0 4vw;
	display: flex;
	align-items: center;
}

.acountAddr h3 {
	color: #8f8f94;
	font-size: 3.5623vw;
}

.acountAddr .addr {
	flex: 1;
	display: flex;
	align-items: center;
	margin-left: 6.1069vw;
	height: 10.1781vw;
	-webkit-border-radius: 1.0178vw;
	border-radius: 1.0178vw;
	background: #f6f6f6;
	padding-left: 4.0712vw;
	box-sizing: border-box;
	color: #ccc;
}

.acountAddr .copy {
	background: #3861fb;
	-webkit-border-radius: 1.0178vw;
	border-radius: 1.0178vw;
	width: 15.2672vw;
	height: 10.1781vw;
	text-align: center;
	color: #fff;
	margin-left: 4.0712vw;
	border: unset;
}

.promptInfo span {
	color: #8f8f94;
	font-size: 3.5623vw;
}

.promptInfo p {
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 19.6px;
	letter-spacing: 0.02em;
	text-align: left;
	color: #0000004D
}
</style>